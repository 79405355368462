.popup_pos {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.main_popup {
  max-height: 80vh;
  overflow-y: scroll;
  background-color: #FFF;
  padding: 24px 20px;
  box-shadow: 0 0 10px #AAA;
  border-radius: 5px;

  @media(max-width: 991px) {
    margin-bottom: 20px;
  }

  @media only screen and (max-width: 500px) {
    max-height: 60vh;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
  }
}

